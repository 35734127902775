import React, { ReactNode } from 'react';
import styles from './Grid.module.scss';
import classNames from 'classnames';

interface GridProps {
  className?: string;
  xs?: number;
  md?: number;
  lg?: number;
  xl?: number;
  children?: ReactNode | ReactNode[];
}

/**
 * @deprecated Use Grid.Row and Grid.Col instead of Grid
 */
export const Grid = ({ children, ...props }: GridProps) => {
  const childrenArray = Array.isArray(children) ? children : [children];

  return (
    <Grid.Row className={styles.gridRow}>
      {childrenArray.map((element, index) => (
        <Grid.Col key={index} {...props}>
          {element}
        </Grid.Col>
      ))}
    </Grid.Row>
  );
};

const Row = ({ className, children }: { className?: string; children: ReactNode }) => {
  return <div className={classNames('row', className)}>{children}</div>;
};

const Col = ({ className, xs, md, lg, xl, children }: GridProps) => {
  const clsName = classNames(className, {
    col: !xs && !md && !lg,
    [`col-${xs}`]: xs,
    [`col-md-${md}`]: md,
    [`col-lg-${lg}`]: lg,
    [`col-xl-${xl}`]: xl,
  });

  return <div className={clsName}>{children}</div>;
};

Grid.Row = Row;
Grid.Col = Col;
