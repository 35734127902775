import React, { PropsWithChildren } from 'react';
import classNames from 'classnames';

interface ContainerProps extends PropsWithChildren {
  fluid?: boolean;
  className?: string;
}

export const Container = ({ className, fluid, children }: ContainerProps) => {
  return (
    <div
      className={classNames(className, {
        'container-fluid': fluid,
        container: !fluid,
      })}
    >
      {children}
    </div>
  );
};
